import React from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const StyledScrollIndicator = styled(motion.span)`
  position: fixed;
  width: 6px;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: ${({ theme }) => theme.color.vista};
  box-shadow: 0 15px 22px -10px rgba(68, 82, 69, 0.4);
  transform: translateY(-100%);
  will-change: transform;
`;

const ScrollIndicator = () => {
  const { scrollYProgress } = useViewportScroll();
  const scrollLength = useTransform(scrollYProgress, [0, 1], ["-100%", "0%"]);
  return <StyledScrollIndicator style={{ translateY: scrollLength }} />;
};

export default ScrollIndicator;
