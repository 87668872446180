import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface IButtonProps {
  forest?: boolean;
  ship?: boolean;
  vista?: boolean;
  transparent?: boolean;
  block?: boolean;
  outline?: boolean;
  large?: boolean;
}

const Button = styled.button<IButtonProps>`
  position: relative;
  padding: 0 1.5vw;
  display: flex;
  align-items: center;
  will-change: scale;
  font-size: 1.2vw;
  font-family: ${({ theme }) => theme.font.sansSerifBold};
  box-shadow: 0 0.75vw 1.1vw -0.5vw rgba(68, 82, 69, 0.4);
  line-height: 2.5vw;
  letter-spacing: 0.05vw;
  border-radius: 1.25vw;
  border: none;
  text-decoration: none;
  text-align: center;
  outline: none;
  touch-action: manipulation;
  backface-visibility: hidden;
  white-space: nowrap;
  transition: all 300ms ease;
  background: transparent;
  cursor: pointer;

  // Hover
  &:hover {
    cursor: pointer;
    opacity: 0.7;

    > svg {
      transform: translate3d(5px, 0, 0);
      circle {
        stroke-dashoffset: 0;
      }
    }
  }

  // Focus
  &:focus {
    outline: none;
  }

  // Active
  &:active {
    transform: scale(0.97);
  }

  // Disabled
  &.disabled,
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  // Icon
  > img,
  > svg {
    margin-left: 1vw;
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
  }

  ${media.desktop`
    font-size: 20px;
    line-height: 40px;
    border-radius: 20px;
  `}
  ${media.mobile`
    font-size: 18px;
    line-height: 28px;
  `}
  ${media.small`
    white-space: normal;
    font-size: 14px;
    line-height: 24px;
  `}

  // Props
  ${(props) =>
    props.forest &&
    css`
      background: ${({ theme }) => theme.color.forest};
      color: ${({ theme }) => theme.color.white};
    `}
  ${(props) =>
    props.ship &&
    css`
      background: ${({ theme }) => theme.color.ship};
      color: ${({ theme }) => theme.color.white};
    `}
  ${(props) =>
    props.vista &&
    css`
      background: ${({ theme }) => theme.color.vista};
      color: ${({ theme }) => theme.color.ship};
    `}
  ${(props) =>
    props.transparent &&
    css`
      color: ${({ theme }) => theme.color.white};
      box-shadow: none;
    `}
  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.large &&
    css`
      line-height: 3vw;
      border-radius: 1.5vw;
      padding: 0 2.5vw;
    `}
`;

export default Button;
