import React, { useEffect } from "react";

// Context
import { useAppContext } from "../../context/AppContext";

// Components
import Section1 from "./Section1";
import Section2 from "./Section2";

export const intersectOptions = {
  threshold: window.innerWidth < 1024 ? 0 : 0.5,
  triggerOnce: true,
};

function Home() {
  const { setHeaderClass } = useAppContext();
  useEffect(() => setHeaderClass(""));

  return (
    <>
      <Section1 />
      <Section2 />
    </>
  );
}

export default Home;
