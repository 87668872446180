import React, { memo } from "react";

const Github = ({ color = "#5A655A" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='23'
      viewBox='0 0 22 23'>
      <path
        fill='none'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M1754,84.0038229 C1749,85.5019114 1749,81.5070086 1747,81.0076457 M1761,87 L1761,83.1349315 L1761,83.1349311 C1761.07588,82.1715573 1760.73349,81.2220817 1760.06,80.528257 C1763.2,80.178703 1766.5,78.9902194 1766.5,73.537177 L1766.5,73.5381757 C1766.5,72.1437848 1765.9632,70.8027658 1765.00069,69.7926746 L1765.00069,69.7926743 C1765.45652,68.5724312 1765.42404,67.2237021 1764.91,66.0267592 C1764.91,66.0267592 1763.73,65.6772052 1761,67.5048733 L1761,67.5048736 C1758.70801,66.8844871 1756.29199,66.8844861 1754,67.504873 C1751.27,65.677205 1750.09,66.026759 1750.09,66.026759 L1750.09,66.0267587 C1749.57638,67.2235816 1749.54415,68.5720211 1750,69.7919546 L1750,69.7919547 C1749.03013,70.8093566 1748.49252,72.1623902 1748.49992,73.5671379 C1748.49992,78.9802312 1751.8,80.1687148 1754.94,80.5582179 L1754.94,80.5582181 C1754.27462,81.2449059 1753.93287,82.1816967 1754,83.1349304 L1754,87'
        transform='translate(-1746 -65)'
      />
    </svg>
  );
};

export default memo(Github);
