export default {
  color: {
    space: "#24212A",
    shuttle: "#222823",
    lunar: "#454B45",
    fin: "#5A655A",
    bud: "#8E958E",
    mantle: "#999F99",
    vista: "#FDFBFA",

    forest: "#445245",
    sweet: "#FA5D51",
    ship: "#3E364A",
    dusk: "#66656C",
    clay: "#747379",
    pip: "#FEFAC7",

    black: "#000000",
    white: "#FFFFFF",
  },
  transition: {
    cubicEase: "cubic-bezier(0.68, 0.38, 0.21, 1.01)",
  },
  layout: {
    gutter: "4vw",
  },
  font: {
    sansSerif: '"Circular Book", sans-serif;',
    sansSerifMedium: '"Circular Medium", sans-serif;',
    sansSerifBold: '"Circular Bold", sans-serif;',
    serif: '"Domaine Disp Nar", serif',
    serifSemibold: '"Domaine Disp Nar Semibold", serif',
    serifSemiboldItalic: '"Domaine Disp Nar Italic", serif',
  },
};
