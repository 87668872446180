import React, { memo } from "react";

const Facebook = ({ color = "#5A655A" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='22'
      viewBox='0 0 13 22'>
      <path
        fill='none'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M1838,66 L1835,66 L1835,66 C1832.23858,66 1830,68.23858 1830,71 C1830,71 1830,71 1830,71 L1830,74 L1827,74 L1827,78 L1830,78 L1830,86 L1834,86 L1834,78 L1837,78 L1838,74 L1834,74 L1834,71 L1834,71 C1834,70.4477152 1834.44772,70 1835,70 L1838,70 L1838,66 Z'
        transform='translate(-1826 -65)'
      />
    </svg>
  );
};

export default memo(Facebook);
