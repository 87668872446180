import React, { memo } from "react";

const Twitter = ({ color = "#5A655A" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='21'
      viewBox='0 0 24 21'>
      <path
        fill='none'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M1920,68.0101499 L1920,68.0101499 C1919.04239,68.6877444 1917.98211,69.205997 1916.86,69.5449463 L1916.86,69.5449464 C1915.23631,67.6721537 1912.4066,67.4743355 1910.53965,69.1031086 C1909.54549,69.9704421 1908.98234,71.2327941 1909,72.5543541 L1909,73.557489 L1909,73.557489 C1905.43066,73.6503332 1902.05202,71.944418 1900,69.0132879 C1900,69.0132879 1896,78.041502 1905,82.0540415 L1905,82.0540419 C1902.94053,83.4563944 1900.48716,84.1595519 1898,84.0603117 C1907,89.0759862 1918,84.0603117 1918,72.5242604 L1918,72.5243164 C1917.99908,72.2448972 1917.9723,71.9661671 1917.92001,71.6917144 L1917.92001,71.6917142 C1918.94062,70.6820388 1919.66084,69.407255 1920,68.0101489 L1920,68.0101499 Z'
        transform='translate(-1897 -67)'
      />
    </svg>
  );
};

export default memo(Twitter);
