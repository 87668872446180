import React from "react";
import styled from "styled-components";

// Utils
import media from "../utils/media";

// Styles
const StyledTags = styled.ul`
  grid-area: top;
  margin-top: 2vw;
  margin-left: 2vw;
  color: ${({ theme }) => theme.color.ship};
  font-family: ${({ theme }) => theme.font.sansSerifMedium};
  position: relative;
  z-index: 1;
  ${media.mobile`
    margin-top: 5vw;
    margin-left: 5vw;
  `}
  > li {
    position: relative;
    padding-left: 0.75vw;
    line-height: 1.5vw;
    margin-bottom: 0.5vw;
    ${media.desktop`
      font-size: 1vw;
      line-height: 1.3vw;
    `}
    ${media.tablet`
      font-size: 1.4vw;
      line-height: 2.8vw;
      margin-bottom: 1vw;
    `}
    ${media.mobile`
      padding-left: 1.5vw;
      // line-height: 4vw;
      margin-bottom: 1.5vw;
      font-size: 16px;
      line-height: 26px;
    `}
    ${media.small`
      font-size: 14px;
      line-height: 24px;
    `};
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background: ${({ theme }) => theme.color.sweet};
      content: "";
    }
  }
`;

// Return
const Tags: React.FC = ({ children, ...props }) => {
  return <StyledTags {...props}>{children}</StyledTags>;
};

export default Tags;
