import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface ITitleThreeProps {
  white?: boolean;
}

const TitleThree = styled.h3<ITitleThreeProps>`
  font-family: ${({ theme }) => theme.font.serifSemibold};
  font-weight: 600;
  color: ${({ theme }) => theme.color.ship};
  font-size: 3vw;
  line-height: 3.6vw;
  ${media.mobile`
    font-size: 5.5vw;
    line-height: 6.5vw;
  `}
  ${media.small`
    font-size: 5vw;
    line-height: 7vw;
  `}

  ${(props) =>
    props.white &&
    css`
      color: ${({ theme }) => theme.color.vista};
    `}
`;

export default TitleThree;
