import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { useForm } from "@formspree/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Utils
import media from "../../utils/media";

// Assets
import * as img from "../../assets";
import { ReactComponent as ShapesContact } from "../../assets/svg/shapes-contact.svg";

// Components
import Button from "../../components/Button";
import Paragraph from "../../components/Paragraph";
import TitleOne from "../../components/TitleOne";
import Textarea from "../../components/Textarea";
import FormControl, {
  FormError,
  FormLabel,
} from "../../components/FormControl";
import Input from "../../components/Input";

// Styles
const Wrapper = styled.section`
  padding: 15vw ${({ theme }) => theme.layout.gutter};
  min-height: 100vh;
  grid-column: span 2;
  position: relative;
  ${media.mobile`
    padding-top: 20vw;
  `}
  ${media.small`
    padding-top: 30vw;
    padding-bottom: 26vw;
  `}
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8vw;
  position: relative;
  max-width: 34vw;
  z-index: 1;
  ${media.tablet`
    max-width: 41vw;
    margin-right: 0;
  `}
  ${media.mobile`
    max-width: unset;
  `}
`;
const Title = styled(TitleOne as any)`
  text-align: left;
`;
const Text = styled(Paragraph as any)`
  color: ${({ theme }) => theme.color.dusk};
  margin-bottom: 3.5vw !important;
`;
const Submit = styled(motion.button)`
  background: ${({ theme }) => theme.color.ship};
  box-shadow: 0 1.5vw 2vw -1.5vw rgba(36, 33, 42, 0.3);
  border-radius: 0.7vw;
  margin: 3vw 0 0 auto;
  padding: 1.7vw 2vw;
  font-size: 1vw;
  line-height: 1.6vw;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.sansSerifBold};
  letter-spacing: 0.05vw;
  width: 15vw;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  ${media.desktop`
    font-size: 16px;
    max-width: 24vw;
  `}
  ${media.tablet`
    padding: 2.5vw 3vw;
    width: 100%;
    max-width: unset;
    border-radius: 10px;
  `}
  ${media.mobile`
    padding: 30px;
  `}
  ${media.small`
    font-size: 14px;
    line-height: 24px;
    padding: 24px 30px;
    border-radius: 14px;
  `}
`;
const ArrowIcon = styled.img`
  margin-left: auto;
`;
const ShapesWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  > svg {
    position: relative;
    left: 53vw;
    top: -27vw;
    ${media.desktop`
      left: 45vw;
      top: -40vw;
    `}
    ${media.tablet`
      left: 60vw;
      top: -70vw;
    `}
    ${media.mobile`
      left: -10vw;
      top: -90vw;
      opacity: 0.2;
    `}
  }
`;
const ButtonLink = styled(Link)`
  margin: 0;
  display: inline-flex;
  ${media.tablet`
    margin-bottom: 0;
  `}
  ${media.mobile`
    margin-left: 0;
  `}
  > button {
    padding: 0;
  }
`;

// Const
const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };
const frameVariants = {
  hover: { scale: 0.95 },
};

const Form = () => {
  const [state, handleSubmit] = useForm("contactForm");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [fields, setFields] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleForm = useCallback(
    (e) => {
      e.preventDefault();
      const currentErrors: any = {};
      if (!fields.name) {
        currentErrors["name"] = "Your name is required";
      }
      if (!fields.email) {
        currentErrors["email"] = "An email is required";
      }
      if (!fields.message) {
        currentErrors["message"] = "A message is required";
      }
      setErrors(currentErrors);
      if (Object.keys(currentErrors).length === 0) {
        handleSubmit(e);
      }
    },
    [fields, handleSubmit]
  );
  const changeValue = useCallback(({ target: { name, value } }) => {
    setFields((prev) => ({ ...prev, [name]: value }));
  }, []);

  if (state.succeeded) {
    return (
      <Wrapper>
        <ShapesWrapper>
          <ShapesContact />
        </ShapesWrapper>
        <Content>
          <Title>Thank you!</Title>
          <Text>
            {`We will get back to you as soon as possible.
            In the meantime, feel free to checkout some
            of our recent work.`}
          </Text>
          <ButtonLink to='/project/1'>
            <Button transparent>View Work</Button>
          </ButtonLink>
        </Content>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ShapesWrapper>
        <ShapesContact />
      </ShapesWrapper>
      <Content>
        <Title>Let's talk.</Title>
        <Text>
          {`Know exactly what you are looking for? Or is it just a 
          brilliant idea? Either way, let’s have a conversation. 
          We’d love to help out and provide any insight from
          our perspective to help you succeed.`}
        </Text>

        <form onSubmit={handleForm}>
          <FormControl>
            <FormLabel htmlFor='name'>Name</FormLabel>
            <Input
              value={fields.name}
              onChange={changeValue}
              type='name'
              name='name'
              placeholder='Enter name...'
            />
            {errors.name && <FormError>{errors.name}</FormError>}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor='email'>Email</FormLabel>
            <Input
              value={fields.email}
              onChange={changeValue}
              type='email'
              name='email'
              placeholder='Enter email...'
            />
            {errors.email && <FormError>{errors.email}</FormError>}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor='message'>Message</FormLabel>
            <Textarea
              value={fields.message}
              onChange={changeValue}
              id='message'
              name='message'
              placeholder='Enter your message...'
            />
            {errors.message && <FormError>{errors.message}</FormError>}
          </FormControl>
          <FormControl>
            <Submit
              whileHover='hover'
              variants={frameVariants}
              transition={transition}
              disabled={state.submitting}
              type='submit'>
              Submit
              <ArrowIcon src={img.arrowRightSmall} />
            </Submit>
          </FormControl>
        </form>
      </Content>
    </Wrapper>
  );
};

export default memo(Form);
