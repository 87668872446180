import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface ITitleFiveProps {
  white?: boolean;
}

const TitleFive = styled.h5<ITitleFiveProps>`
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.font.serifSemibold};
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 1.6vw;
  letter-spacing: 0.04vw;
  ${media.desktop`
    font-size: 1.5vw;
    line-height: 1.8vw;
  `}
  ${media.tablet`
    font-size: 2vw;
    line-height: 2.1vw;
  `}
  ${media.mobile`
    font-size: 2.25vw;
    line-height: 2.25vw;
    white-space: normal;
  `}
  ${media.small`
    font-size: 4vw;
    line-height: 5vw;
    white-space: normal;
  `}
  
  ${(props) =>
    props.white &&
    css`
      color: ${({ theme }) => theme.color.vista};
    `}
`;

export default TitleFive;
