import { css } from "styled-components";

// Utils
import media from "../utils/media";

const InputAppearance = css`
  appearance: none;
  display: flex;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.color.ship};
  background-color: ${({ theme }) => theme.color.mantle};
  border-radius: 0.7vw;
  padding: 1.25vw 1.5vw;
  line-height: 1.5vw;
  font-size: 1.2vw;
  font-family: ${({ theme }) => theme.font.sansSerifBold};
  transition: 0.2s all ${({ theme }) => theme.transition.cubicEase};

  ${media.tablet`
    font-size: 16px;
    padding: 15px;
    line-height: 18px;
    border-radius: 10px;
  `}
  ${media.mobile`
    font-size: 14px;
    padding: 15px;
    line-height: 14px;
    border-radius: 10px;
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.font.sansSerif};
    color: ${({ theme }) => theme.color.dusk};
  }
`;

export default InputAppearance;
