import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface ITitleOneProps {
  white?: boolean;
  ship?: boolean;
}

const TitleOne = styled.h1<ITitleOneProps>`
  font-family: ${({ theme }) => theme.font.serifSemibold};
  font-weight: 600;
  color: ${({ theme }) => theme.color.shuttle};
  font-size: 5vw;
  line-height: 6vw;
  white-space: pre-line;
  ${media.desktop`
    font-size: 6vw;
    line-height: 8vw;
  `}
  ${media.tablet`
    font-size: 9vw;
    line-height: 10vw;
  `}
  ${media.small`
    font-size: 10vw;
    line-height: 12vw;
    white-space: normal;
  `}

  ${(props) =>
    props.white &&
    css`
      color: ${({ theme }) => theme.color.vista};
    `}
  
  ${(props) =>
    props.ship &&
    css`
      color: ${({ theme }) => theme.color.ship};
    `}
`;

export default TitleOne;
