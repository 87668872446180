import { css } from "styled-components";

const mediaQueries: Record<string, string> = {
  small: "only screen and (max-width: 500px)",
  mobile: "only screen and (max-width: 767px)",
  tablet: "only screen and (max-width: 1023px)",
  desktop: "only screen and (max-width: 1439px)",
  retina:
    "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)",
};

// Iterate through the sizes and create a media template
const media = Object.keys(mediaQueries).reduce((acc: any, label) => {
  acc[label] = (styles: string) => css`
    @media ${mediaQueries[label]} {
      ${styles}
    }
  `;
  return acc;
}, {});

export default media;
