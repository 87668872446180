import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";

// Utils
import media from "../../utils/media";

// Context
import { useAppContext } from "../../context/AppContext";

// Components
import Button from "../../components/Button";
import ProjectDetail from "../../components/ProjectDetail";

// Assets
import * as img from "../../assets";

// Data
import { projectIntros } from "../../data/projects";

// Styles
const Wrapper = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.color.dusk};
`;
const Footer = styled.footer`
  // position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  img {
    width: 1vw;
    margin: 1vw;
    ${media.desktop`
    width: 14px;
  `}
  }
  a {
    padding: 4vw ${({ theme }) => theme.layout.gutter};
    transition: all 300ms ease;
    ${media.mobile`
      justify-content: center;
      display: flex;
    `}
    &:first-child button {
      margin-left: auto;
      ${media.mobile`
        margin-left: unset;
      `}
    }
    &:hover {
      background: rgba(62, 54, 74, 0.2);
    }
    button {
      color: ${({ theme }) => theme.color.ship};
      font-family: ${({ theme }) => theme.font.sansSerifMedium};
      pointer-events: none;
      &:hover {
        opacity: 1;
      }
    }
  }
}
`;

let easing = [0.175, 0.85, 0.42, 0.96];

const textVariants = {
  exit: { y: 100, opacity: 0, transition: { duration: 0.5, ease: easing } },
  enter: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.1, duration: 0.5, ease: easing },
  },
};
const footerVariants = {
  exit: { y: 150, opacity: 0, transition: { duration: 0.5, ease: easing } },
  enter: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.3, duration: 0.5, ease: easing },
  },
};

const Project = () => {
  const { setHeaderClass } = useAppContext();
  useEffect(() => {
    setHeaderClass("project");
    window.scrollTo(0, 0);
  }, [setHeaderClass]);

  const { id } = useParams<{ id: string }>();
  if (id === undefined) {
    return <></>;
  }

  const parsedId = parseInt(id, 10) - 1;
  const prevId = parsedId > 0 ? parsedId - 1 : null;
  const nextId = parsedId < projectIntros.length - 1 ? parsedId + 1 : null;

  return (
    <Wrapper>
      <motion.div
        key={`project_${parsedId}`}
        initial='exit'
        animate='enter'
        exit='exit'>
        <motion.div variants={textVariants}>
          <ProjectDetail data={projectIntros[parsedId]} />
        </motion.div>
        <motion.div variants={footerVariants}>
          <Footer>
            {prevId || prevId === 0 ? (
              <Link to={`/project/${prevId + 1}`}>
                <Button transparent>
                  <img alt='Arrow' src={img.arrowLeftAlt} />
                  Prev Project
                </Button>
              </Link>
            ) : (
              <div></div>
            )}
            {nextId ? (
              <Link to={`/project/${nextId + 1}`}>
                <Button transparent>
                  Next Project
                  <img alt='Arrow' src={img.arrowRightAlt} />
                </Button>
              </Link>
            ) : (
              <div></div>
            )}
          </Footer>
        </motion.div>
      </motion.div>
    </Wrapper>
  );
};

export default Project;
