// Logo
export const logo = require("./logo/logo.svg");

// Mark
export const mark1 = require("./logo/mark.png");
export const mark2 = require("./logo/mark@2x.png");
export const mark3 = require("./logo/mark@3x.png");

// Svg
export const arrowLeft = require("./svg/arrow-left.svg");
export const arrowRight = require("./svg/arrow-right.svg");
export const arrowLeftAlt = require("./svg/arrow-left-alt.svg");
export const arrowRightAlt = require("./svg/arrow-right-alt.svg");
export const arrowRightSmall = require("./svg/arrow-right-small.svg");
export const arrowCircle = require("./svg/arrow-circle.svg");
export const facebook = require("./svg/facebook.svg");
export const github = require("./svg/github.svg");
export const twitter = require("./svg/twitter.svg");
export const shapes = require("./svg/shapes.svg");
export const shapesQuote = require("./svg/shapes-quote.svg");
export const screen = require("./svg/screen.svg");
export const lines = require("./svg/lines.svg");
export const link = require("./svg/link.svg");

// Img
export const boxfitThumb1 = require("./img/img-boxfit-thumb.png");
export const boxfitThumb2 = require("./img/img-boxfit-thumb@2x.png");
export const boxfitThumb3 = require("./img/img-boxfit-thumb@3x.png");
export const axumThumb1 = require("./img/img-axum-thumb.png");
export const axumThumb2 = require("./img/img-axum-thumb@2x.png");
export const axumThumb3 = require("./img/img-axum-thumb@3x.png");
export const madeThumb1 = require("./img/img-made-thumb.png");
export const madeThumb2 = require("./img/img-made-thumb@2x.png");
export const madeThumb3 = require("./img/img-made-thumb@3x.png");
export const dentistThumb1 = require("./img/img-dentist-thumb.png");
export const dentistThumb2 = require("./img/img-dentist-thumb@2x.png");
export const dentistThumb3 = require("./img/img-dentist-thumb@3x.png");
export const growThumb1 = require("./img/img-grow-thumb.png");
export const growThumb2 = require("./img/img-grow-thumb@2x.png");
export const growThumb3 = require("./img/img-grow-thumb@3x.png");
export const keeloThumb1 = require("./img/img-keelo-thumb.png");
export const keeloThumb2 = require("./img/img-keelo-thumb@2x.png");
export const keeloThumb3 = require("./img/img-keelo-thumb@3x.png");
export const involvioThumb1 = require("./img/img-involvio-thumb.png");
export const involvioThumb2 = require("./img/img-involvio-thumb@2x.png");
export const involvioThumb3 = require("./img/img-involvio-thumb@3x.png");
export const mygoodThumb1 = require("./img/img-mygood-thumb.png");
export const mygoodThumb2 = require("./img/img-mygood-thumb@2x.png");
export const mygoodThumb3 = require("./img/img-mygood-thumb@3x.png");

// Img - Boxfit
export const boxfitScreen1 = require("./img/img-boxfit-screen.png");
export const boxfitScreen2 = require("./img/img-boxfit-screen@2x.png");
export const boxfitScreen3 = require("./img/img-boxfit-screen@3x.png");
export const boxfitQuote1 = require("./img/img-boxfit-quote.png");
export const boxfitQuote2 = require("./img/img-boxfit-quote@2x.png");
export const boxfitQuote3 = require("./img/img-boxfit-quote@3x.png");
export const boxfitProductOne1 = require("./img/img-boxfit-product-1.png");
export const boxfitProductOne2 = require("./img/img-boxfit-product-1@2x.png");
export const boxfitProductOne3 = require("./img/img-boxfit-product-1@3x.png");
export const boxfitProductTwo1 = require("./img/img-boxfit-product-2.png");
export const boxfitProductTwo2 = require("./img/img-boxfit-product-2@2x.png");
export const boxfitProductTwo3 = require("./img/img-boxfit-product-2@3x.png");
export const boxfitProductThree1 = require("./img/img-boxfit-product-3.png");
export const boxfitProductThree2 = require("./img/img-boxfit-product-3@2x.png");
export const boxfitProductThree3 = require("./img/img-boxfit-product-3@3x.png");

// Img - Axum
export const axumScreen1 = require("./img/img-axum-screen.png");
export const axumScreen2 = require("./img/img-axum-screen@2x.png");
export const axumScreen3 = require("./img/img-axum-screen@3x.png");
export const axumQuote1 = require("./img/img-axum-quote.png");
export const axumQuote2 = require("./img/img-axum-quote@2x.png");
export const axumQuote3 = require("./img/img-axum-quote@3x.png");
export const axumProductOne1 = require("./img/img-axum-product-1.png");
export const axumProductOne2 = require("./img/img-axum-product-1@2x.png");
export const axumProductOne3 = require("./img/img-axum-product-1@3x.png");
export const axumProductTwo1 = require("./img/img-axum-product-2.png");
export const axumProductTwo2 = require("./img/img-axum-product-2@2x.png");
export const axumProductTwo3 = require("./img/img-axum-product-2@3x.png");
export const axumProductThree1 = require("./img/img-axum-product-3.png");
export const axumProductThree2 = require("./img/img-axum-product-3@2x.png");
export const axumProductThree3 = require("./img/img-axum-product-3@3x.png");
export const axumProductFour1 = require("./img/img-axum-product-4.png");
export const axumProductFour2 = require("./img/img-axum-product-4@2x.png");
export const axumProductFour3 = require("./img/img-axum-product-4@3x.png");

// Img - Dentist Advisors
export const dentistScreen1 = require("./img/img-dentist-screen.png");
export const dentistScreen2 = require("./img/img-dentist-screen@2x.png");
export const dentistScreen3 = require("./img/img-dentist-screen@3x.png");
export const dentistQuote1 = require("./img/img-dentist-quote.png");
export const dentistQuote2 = require("./img/img-dentist-quote@2x.png");
export const dentistQuote3 = require("./img/img-dentist-quote@3x.png");
export const dentistProductOne1 = require("./img/img-dentist-product-1.png");
export const dentistProductOne2 = require("./img/img-dentist-product-1@2x.png");
export const dentistProductOne3 = require("./img/img-dentist-product-1@3x.png");
export const dentistProductTwo1 = require("./img/img-dentist-product-2.png");
export const dentistProductTwo2 = require("./img/img-dentist-product-2@2x.png");
export const dentistProductTwo3 = require("./img/img-dentist-product-2@3x.png");
export const dentistProductThree1 = require("./img/img-dentist-product-3.png");
export const dentistProductThree2 = require("./img/img-dentist-product-3@2x.png");
export const dentistProductThree3 = require("./img/img-dentist-product-3@3x.png");
export const dentistProductFour1 = require("./img/img-dentist-product-4.png");
export const dentistProductFour2 = require("./img/img-dentist-product-4@2x.png");
export const dentistProductFour3 = require("./img/img-dentist-product-4@3x.png");
export const dentistProductFive1 = require("./img/img-dentist-product-5.png");
export const dentistProductFive2 = require("./img/img-dentist-product-5@2x.png");
export const dentistProductFive3 = require("./img/img-dentist-product-5@3x.png");

// Img - Grow
export const growScreen1 = require("./img/img-grow-screen.png");
export const growScreen2 = require("./img/img-grow-screen@2x.png");
export const growScreen3 = require("./img/img-grow-screen@3x.png");
export const growQuote1 = require("./img/img-grow-quote.png");
export const growQuote2 = require("./img/img-grow-quote@2x.png");
export const growQuote3 = require("./img/img-grow-quote@3x.png");
export const growProductOne1 = require("./img/img-grow-product-1.png");
export const growProductOne2 = require("./img/img-grow-product-1@2x.png");
export const growProductOne3 = require("./img/img-grow-product-1@3x.png");
export const growProductTwo1 = require("./img/img-grow-product-2.png");
export const growProductTwo2 = require("./img/img-grow-product-2@2x.png");
export const growProductTwo3 = require("./img/img-grow-product-2@3x.png");
export const growProductThree1 = require("./img/img-grow-product-3.png");
export const growProductThree2 = require("./img/img-grow-product-3@2x.png");
export const growProductThree3 = require("./img/img-grow-product-3@3x.png");

// Img - Keelo
export const keeloScreen1 = require("./img/img-keelo-screen.png");
export const keeloScreen2 = require("./img/img-keelo-screen@2x.png");
export const keeloScreen3 = require("./img/img-keelo-screen@3x.png");
export const keeloQuote1 = require("./img/img-keelo-quote.png");
export const keeloQuote2 = require("./img/img-keelo-quote@2x.png");
export const keeloQuote3 = require("./img/img-keelo-quote@3x.png");
export const keeloProductOne1 = require("./img/img-keelo-product-1.png");
export const keeloProductOne2 = require("./img/img-keelo-product-1@2x.png");
export const keeloProductOne3 = require("./img/img-keelo-product-1@3x.png");
export const keeloProductTwo1 = require("./img/img-keelo-product-2.png");
export const keeloProductTwo2 = require("./img/img-keelo-product-2@2x.png");
export const keeloProductTwo3 = require("./img/img-keelo-product-2@3x.png");
export const keeloProductThree1 = require("./img/img-keelo-product-3.png");
export const keeloProductThree2 = require("./img/img-keelo-product-3@2x.png");
export const keeloProductThree3 = require("./img/img-keelo-product-3@3x.png");
export const keeloProductFour1 = require("./img/img-keelo-product-4.png");
export const keeloProductFour2 = require("./img/img-keelo-product-4@2x.png");
export const keeloProductFour3 = require("./img/img-keelo-product-4@3x.png");

// Img - MyGood
export const mygoodScreen1 = require("./img/img-mygood-screen.png");
export const mygoodScreen2 = require("./img/img-mygood-screen@2x.png");
export const mygoodScreen3 = require("./img/img-mygood-screen@3x.png");
export const mygoodQuote1 = require("./img/img-mygood-quote.png");
export const mygoodQuote2 = require("./img/img-mygood-quote@2x.png");
export const mygoodQuote3 = require("./img/img-mygood-quote@3x.png");
export const mygoodProductOne1 = require("./img/img-mygood-product-1.png");
export const mygoodProductOne2 = require("./img/img-mygood-product-1@2x.png");
export const mygoodProductOne3 = require("./img/img-mygood-product-1@3x.png");
export const mygoodProductTwo1 = require("./img/img-mygood-product-2.png");
export const mygoodProductTwo2 = require("./img/img-mygood-product-2@2x.png");
export const mygoodProductTwo3 = require("./img/img-mygood-product-2@3x.png");
export const mygoodProductThree1 = require("./img/img-mygood-product-3.png");
export const mygoodProductThree2 = require("./img/img-mygood-product-3@2x.png");
export const mygoodProductThree3 = require("./img/img-mygood-product-3@3x.png");
export const mygoodProductFour1 = require("./img/img-mygood-product-4.png");
export const mygoodProductFour2 = require("./img/img-mygood-product-4@2x.png");
export const mygoodProductFour3 = require("./img/img-mygood-product-4@3x.png");
export const mygoodProductFive1 = require("./img/img-mygood-product-5.png");
export const mygoodProductFive2 = require("./img/img-mygood-product-5@2x.png");
export const mygoodProductFive3 = require("./img/img-mygood-product-5@3x.png");
export const mygoodProductSix1 = require("./img/img-mygood-product-6.png");
export const mygoodProductSix2 = require("./img/img-mygood-product-6@2x.png");
export const mygoodProductSix3 = require("./img/img-mygood-product-6@3x.png");
