// Assets
import * as img from "../assets";

export const projectIntros = [
  {
    projectKey: "boxfit",
    num: "01",
    tags: ["brand", "design", "development"],
    version: "dark",
    srcStyle: { width: "16.2vw" },
    desc: {
      title: "Boxfit",
      link: "https://www.getboxfit.co",
      shortText:
        "Rethinking how a gym manages\n their members and programs\n their workouts.",
      text:
        "We built Boxfit to help gyms and members engage in an entirely new way. Sure, it still needed to operate as a leaderboard, result tracker and subscription manager... but we felt it could provide an even stronger platform of communication for its users.\n\n We focused on the identity, style, experience, and platform of Boxfit from every angle. We didn't build Boxfit to be a sandbox of features. Instead, we focused on the critical needs of gyms. These needs apply to the gym as much as the members. We are carefully considering every detail to create an essential tool for CrossFit affiliates without bloating that experience full of unnecessary features.\n\n Boxfit is in active development and has a lot of engaging features yet to be built. Follow along the journey as we transform the way gyms utilize technology.",
    },
    screenHome: {
      src: img.boxfitThumb1,
      srcset: img.boxfitThumb2,
      srcset3x: img.boxfitThumb3,
    },
    screen: {
      src: img.boxfitScreen1,
      srcset: img.boxfitScreen2,
      srcset3x: img.boxfitScreen3,
      alt: "Boxfit Member List",
    },
    quote: {
      src: img.boxfitQuote1,
      srcset: img.boxfitQuote2,
      srcset3x: img.boxfitQuote3,
      alt: "Boxfit / Dallin Frampton",
      author: "Dallin Frampton",
      text:
        "“Boxfit is an effective tool to manage gym memberships, programming & results.”",
    },
    product: {
      title: "Product Design",
      desc:
        "Building Boxfit was extremely complex. Understanding how workouts blended together with the day to day use of the application from both the gym and member created opportunities that were quiet challenging, fun and a blast to expound on.",
      products: [
        {
          src: img.boxfitProductOne1,
          srcset: img.boxfitProductOne2,
          srcset3x: img.boxfitProductOne3,
          alt: "Boxfit / Create Workouts",
          desc:
            "Daily workout screen where members\n can create and view workouts.",
        },
        {
          src: img.boxfitProductTwo1,
          srcset: img.boxfitProductTwo2,
          srcset3x: img.boxfitProductTwo3,
          alt: "Boxfit / Effecient Programming",
          desc: "Directional design for effecient\n programming for the gyms.",
        },
        {
          src: img.boxfitProductThree1,
          srcset: img.boxfitProductThree2,
          srcset3x: img.boxfitProductThree3,
          alt: "Boxfit / User Management",
          desc:
            "User management and\n communication with multi-layered\n design elements.",
        },
      ],
    },
  },
  {
    projectKey: "axum",
    num: "02",
    tags: ["design", "development"],
    version: "dark",
    srcStyle: { width: "14.75vw" },
    desc: {
      title: "Axum",
      link: "https://www.axum.co",
      shortText:
        "Corporate health initiative which\n increases awareness & helps employers\n screen potential health issues.",
      text:
        "Axum provides a unique and individual approach to corporate wellness. They provide a results-driven solution to every employee and the ever-rising costs of healthcare. By providing personal fitness and nutrition programs, they are able to harness motivation and cultivate exclusive employee development.",
    },
    screenHome: {
      src: img.axumThumb1,
      srcset: img.axumThumb2,
      srcset3x: img.axumThumb3,
    },
    screen: {
      src: img.axumScreen1,
      srcset: img.axumScreen2,
      srcset3x: img.axumScreen3,
      alt: "Axum Dashboard",
    },
    quote: {
      src: img.axumQuote1,
      srcset: img.axumQuote2,
      srcset3x: img.axumQuote3,
      alt: "Axum / Miki Eberhardt",
      author: "Miki Eberhardt",
      text:
        "“Matt was obsessed with the details of understanding our product.”",
    },
    product: {
      title: "Application Design",
      desc:
        "This product is complex, unique, and geared entirely towards a large group of demographics. Daily screenings, HIPPA requirements, user access coupled with detailed medical information introduced challenges.\n\n Our approach was to step back and focus on the most critical peice of information for employees, businesses as well as provide a functional tool for Axum to gather this information properly.",
      products: [
        {
          src: img.axumProductOne1,
          srcset: img.axumProductOne2,
          srcset3x: img.axumProductOne3,
          alt: "Axum / Client Dashboard",
          desc:
            "Client dashboard offering a high-level view\n  of meaningful metrics & data.",
        },
        {
          src: img.axumProductTwo1,
          srcset: img.axumProductTwo2,
          srcset3x: img.axumProductTwo3,
          alt: "Axum / Screening Data",
          desc:
            "HR managers are able to view\n take notes, and understand complex health\n screenings of their employees.",
        },
        {
          src: img.axumProductThree1,
          srcset: img.axumProductThree2,
          srcset3x: img.axumProductThree3,
          alt: "Axum / Individual Profiles",
          desc:
            "Individual health profiles updated\n continuously using screenings.",
        },
        {
          src: img.axumProductFour1,
          srcset: img.axumProductFour2,
          srcset3x: img.axumProductFour3,
          alt: "Axum / Create Workouts",
          desc:
            "Detailed history for employees\n help track progress, changes and status.",
        },
      ],
    },
  },
  {
    projectKey: "dentist_advisors",
    num: "03",
    tags: ["design"],
    version: "light",
    srcStyle: { width: "27vw" },
    desc: {
      title: "Denitst\n Advisors",
      link: null,
      shortText:
        "Dentist Advisors is a fee-only,\n fiduciary advisor, focused exclusively\n on dentists.",
      text:
        "Dentist Advisors offers financial planning & investment managment to a specific demographic... dentists. Having built a proprietary element model for the clients, Dentist Advisors needed a unique application allowing them the easy communication, ease of funcationality, and scalability. \n\nDesigned for quick information gathering, decissive actions, and fluid experience for both client and advisor, the product navigates effortlessly inbetween the most critical areas the advisors required. We worked tirelessly to provide a creative solution that scaled regardless of the number of clients.",
    },
    screenHome: {
      src: img.dentistThumb1,
      srcset: img.dentistThumb2,
      srcset3x: img.dentistThumb3,
    },
    screen: {
      src: img.dentistScreen1,
      srcset: img.dentistScreen2,
      srcset3x: img.dentistScreen3,
      alt: "Boxfit Member List",
    },
    quote: {
      src: img.dentistQuote1,
      srcset: img.dentistQuote2,
      srcset3x: img.dentistQuote3,
      alt: "Dentist Advisors / Product User",
      author: "Product User",
      text:
        "“Our product & company needed this thought process greatly, so thank you!”",
    },
    product: {
      title: "Application Design",
      desc:
        "We focused heavily on the advisor experience and how we could provide a seamless application that was easy to use and extremely helpful.\n\n We found many ways to optimize the presentation of data for both client and advisor through that study. The application was built utilizing layers of importance to best help information be distrubted easily and quickly.",
      products: [
        {
          src: img.dentistProductOne1,
          srcset: img.dentistProductOne2,
          srcset3x: img.dentistProductOne3,
          alt: "Dentist Advisors / Dashboard",
          desc:
            "The dashboard offers a quick glance\n at urgent matters facing an advisor.",
        },
        {
          src: img.dentistProductTwo1,
          srcset: img.dentistProductTwo2,
          srcset3x: img.dentistProductTwo3,
          alt: "Dentist Advisors / Client Detail",
          desc:
            "A clients detail view provides an\n in-depth measurement into their various\n elements and performance.",
        },
        {
          src: img.dentistProductThree1,
          srcset: img.dentistProductThree2,
          srcset3x: img.dentistProductThree3,
          alt: "Dentist Advisors / Urgent Alert",
          desc:
            "Constant communication and audits\n allowed advisors to handle numerous clients\n without sacrifice.",
        },
        {
          src: img.dentistProductFour1,
          srcset: img.dentistProductFour2,
          srcset3x: img.dentistProductFour3,
          alt: "Dentist Advisors / Messaging",
          desc:
            "Instant communication allows\n client to advisor communication to stay\n segmented and informative.",
        },
        {
          src: img.dentistProductFive1,
          srcset: img.dentistProductFive2,
          srcset3x: img.dentistProductFive3,
          alt: "Dentist Advisors / Compose Message",
          desc: "Easily compose messages to\n individual clients or groups.",
        },
      ],
    },
  },
  {
    projectKey: "grow",
    num: "04",
    tags: ["brand", "design"],
    version: "light",
    srcStyle: { width: "27vw" },
    desc: {
      title: "Grow",
      link: "https://www.grow.com",
      shortText:
        "Grow keeps a pulse on your company’s\n health and gives you full-funnel visibility\n so you can grow faster.",
      text:
        "Grow came to Lift22 before they even knew what to call themselves. They had an idea, a model of what they wanted to accomplish and asked for help. Throughout our collaboration, Grow developed into a tangible, marketable product that was ready to take on the business intelligence world.\n\n We started from nothing and focused on creating the Grow brand to clearly define their visual values, ambitions and styles. Logo mark, name mark, photography, gradients, purposful angles, and distinct colors were brought together forming the brand known today.\n\n",
    },
    screenHome: {
      src: img.growThumb1,
      srcset: img.growThumb2,
      srcset3x: img.growThumb3,
    },
    screen: {
      src: img.growScreen1,
      srcset: img.growScreen2,
      srcset3x: img.growScreen3,
      alt: "Grow Collateral Branding",
    },
    quote: {
      src: img.growQuote1,
      srcset: img.growQuote2,
      srcset3x: img.growQuote3,
      alt: "Grow / Product User",
      author: "Product User",
      text:
        "“The look and feel of this brand reminds me of the coast. I love it.”",
    },
    product: {
      title: "Branding &\n Conceptual\n Design",
      desc:
        "The Grow brand is an evolution of thought. Out of nothing stemmed a dominant force in business intelligence.\n\n Additionally, we built the conceptual design for their core application, user experience and the best way to present the business intelligence provided within their application. For further details, visit Grow directly.",
      products: [
        {
          src: img.growProductOne1,
          srcset: img.growProductOne2,
          srcset3x: img.growProductOne3,
          alt: "Grow / Mark",
          desc:
            "Grow is a symbolic representation of the graph;\n growing towards the sun out of thick mud\n into brilliant colors from blue to green.",
        },
        {
          src: img.growProductTwo1,
          srcset: img.growProductTwo2,
          srcset3x: img.growProductTwo3,
          alt: "Grow / Create Workouts",
          desc:
            "The Grow logo portrays a wave\n of simplicity and harmony. It is stable\n and rigid to provide confidence.",
        },
        {
          src: img.growProductThree1,
          srcset: img.growProductThree2,
          srcset3x: img.growProductThree3,
          alt: "Grow / Create Workouts",
          desc:
            "A variation that was not used\n but still built to resemble\n the values desired.",
        },
      ],
    },
  },
  {
    projectKey: "keelo",
    num: "05",
    tags: ["design"],
    version: "light",
    srcStyle: { width: "27vw" },
    desc: {
      title: "Keelo",
      link: "https://apps.apple.com/app/apple-store/id1004824537",
      shortText:
        "A high intensity functional fitness app\n program that delivers real,\n measurable results.",
      text:
        "One of the best high intensity functional fitness programs / apps that exists if you ask my opinion. It delivers real, measurable results and does so through both iOS and Android apps. Keelo combines strength, cardio, and conditioning into one program that you can easily view, navigate and experience within their innovative app.\n\n Workout with your friends, challenge them, compete in competitions... do it all! Keelo's app is built for all levels and we have made sure to make it easy to use while providing complex information for those who need it.\n\n",
    },
    screenHome: {
      src: img.keeloThumb1,
      srcset: img.keeloThumb2,
      srcset3x: img.keeloThumb3,
    },
    screen: {
      src: img.keeloScreen1,
      srcset: img.keeloScreen2,
      srcset3x: img.keeloScreen3,
      alt: "Keelo App",
    },
    quote: {
      src: img.keeloQuote1,
      srcset: img.keeloQuote2,
      srcset3x: img.keeloQuote3,
      alt: "Keelo / Hoon Kim",
      author: "Hoon Kim",
      text: "“We have loved the design process and working with Matt!”",
    },
    product: {
      title: "App\n Design",
      desc:
        "Keelo is an intuitive app built to help people workout on their own schedule. Through incremental implementation and design imporovement, this app has increased user base, added premium users, and increased overall satisfaction (4.8 stars) month over month!",
      products: [
        {
          src: img.keeloProductOne1,
          srcset: img.keeloProductOne2,
          srcset3x: img.keeloProductOne3,
          alt: "Keelo / Profile",
          desc: null,
        },
        {
          src: img.keeloProductTwo1,
          srcset: img.keeloProductTwo2,
          srcset3x: img.keeloProductTwo3,
          alt: "Keelo / App Workout Achievment",
          desc:
            "Simple to understand screens\n displaying achievements,\n workouts, and your profile.",
        },
        {
          src: img.keeloProductThree1,
          srcset: img.keeloProductThree2,
          srcset3x: img.keeloProductThree3,
          alt: "Keelo / Create Workouts",
          desc:
            "Keelo pushes individuals\n like a coach would. Providing\n insight & instruction.",
        },
        {
          src: img.keeloProductFour1,
          srcset: img.keeloProductFour2,
          srcset3x: img.keeloProductFour3,
          alt: "Keelo / Create Workouts",
          desc:
            "Calculate macros using\n scientific data and simple to\n follow guidelines.",
        },
      ],
    },
  },
  {
    projectKey: "my_good",
    num: "06",
    tags: ["design", "development"],
    version: "dark",
    srcStyle: { width: "27vw" },
    desc: {
      title: "My Good",
      link: "",
      shortText:
        "An app built to connect people who\n want to do a little good with the\n organizations that need their help.",
      text:
        "Utilizing technology to do a little good in the world, that is the intent of My Good. \n\nThe founders of My Good sought Lift22 Studio out and ask that we team up to build their vision. We obliged, obviously. Through an intense collaboration, we designed and built My Good to give social challenges a place to actually make an impact.\n\n Whether it is the ALS Ice Bucket challenge or a racial injustice, its core function is to allow people to donate money to charities by challenge their friends. A fantastic idea and in our opinion, even better execution 😏 ... but something this world needed a bit more of. Impactful change using technology that can so easily push as apart.\n\n",
    },
    screenHome: {
      src: img.mygoodThumb1,
      srcset: img.mygoodThumb2,
      srcset3x: img.mygoodThumb3,
    },
    screen: {
      src: img.mygoodScreen1,
      srcset: img.mygoodScreen2,
      srcset3x: img.mygoodScreen3,
      alt: "My Good Challenge",
    },
    quote: {
      src: img.mygoodQuote1,
      srcset: img.mygoodQuote2,
      srcset3x: img.mygoodQuote3,
      alt: "My Good / Kimber",
      author: "Kimber",
      text: "“We loved working with Matt. Cannot wait to build more things!”",
    },
    product: {
      title: "Design &\n Development",
      desc:
        "We had a lot of fun building out the interface for My Good. We wanted a playful, energetic interface yet sympathetic and meaningful. \n\nA complex application with time-sensative challenges, our build allowed the experience to be front and center and not get in the way with over-the-top animations and interactions. ",
      products: [
        {
          src: img.mygoodProductOne1,
          srcset: img.mygoodProductOne2,
          srcset3x: img.mygoodProductOne3,
          alt: "My Good / Discover Challenges",
          desc:
            "Browse, search and discover\n challenges you want to be apart of.",
        },
        {
          src: img.mygoodProductTwo1,
          srcset: img.mygoodProductTwo2,
          srcset3x: img.mygoodProductTwo3,
          alt: "My Good / Charity Profiles",
          desc:
            "Charity profiles to know exactly\n who you are supporting with your votes.",
        },
        {
          src: img.mygoodProductThree1,
          srcset: img.mygoodProductThree2,
          srcset3x: img.mygoodProductThree3,
          alt: "My Good / Challenge",
          desc: "View challenges and even\n pledge money to the ones you love.",
        },
        {
          src: img.mygoodProductFour1,
          srcset: img.mygoodProductFour2,
          srcset3x: img.mygoodProductFour3,
          alt: "My Good / Challenge Details",
          desc: "Interact, vote and comment on\n challenges with your friends.",
        },
        {
          src: img.mygoodProductFive1,
          srcset: img.mygoodProductFive2,
          srcset3x: img.mygoodProductFive3,
          alt: "My Good / Search",
          desc: "Search for people you want\n to be friends with.",
        },
        {
          src: img.mygoodProductSix1,
          srcset: img.mygoodProductSix2,
          srcset3x: img.mygoodProductSix3,
          alt: "My Good / Create Challenge",
          desc:
            "Create your own challenge and\n target your friends to complete it.",
        },
      ],
    },
  },
  // {
  //   projectKey: "involvio",
  //   num: "07",
  //   tags: ["design", "development"],
  //   version: "light",
  //   srcStyle: { width: "27vw" },
  //   desc: {
  //     title: "Involvio",
  //     link: "https://www.involvio.com",
  //     shortText: "Involvio offers cutting edge products that\n help forward-thinking universities\n improve student engagement.",
  //     text:
  //       "Involvio helps colleges and universities leverage mobile and AI to improve campus safety and graduate more students.\n\n Meeting the needs of the modern student begins with personal, mobile experiences that resonate throughout their academic journey.\n\n",
  //   },
  //   screenHome: {
  //     src: img.involvioThumb1,
  //     srcset: img.involvioThumb2,
  //     srcset3x: img.involvioThumb3,
  //   },
  //   screen: {
  //     src: img.involvioScreen1,
  //     srcset: img.involvioScreen2,
  //     srcset3x: img.involvioScreen3,
  //     alt: "Boxfit Member List",
  //   },
  //   quote: {
  //     src: img.involvioQuote1,
  //     srcset: img.involvioQuote2,
  //     srcset3x: img.involvioQuote3,
  //     alt: "Involvio / Ari",
  //     author: "Involvio User",
  //     text:
  //       "“Matt was obsessed with the details of understanding our product.”",
  //   },
  //   product: {
  //     title: "Branding &\n Conceptual\n Design",
  //     desc:
  //       "This is amazing and a detailed\n description about what we do\n and our work.",
  //     products: [
  //       {
  //         src: img.involvioProductOne1,
  //         srcset: img.involvioProductOne2,
  //         srcset3x: img.involvioProductOne3,
  //         alt: "Boxfit / Create Workouts",
  //         desc:
  //           "Daily workout screen where members\n can create and view workouts.",
  //       },
  //       {
  //         src: img.involvioProductTwo1,
  //         srcset: img.involvioProductTwo2,
  //         srcset3x: img.involvioProductTwo3,
  //         alt: "Boxfit / Create Workouts",
  //         desc:
  //           "Daily workout screen where members\n can create and view workouts.",
  //       },
  //       {
  //         src: img.involvioProductThree1,
  //         srcset: img.involvioProductThree2,
  //         srcset3x: img.involvioProductThree3,
  //         alt: "Boxfit / Create Workouts",
  //         desc:
  //           "Daily workout screen where members\n can create and view workouts.",
  //       },
  //     ],
  //   },
  // },
];
