import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import styled from "styled-components";
import { Reset } from "styled-reset";

import Home from "./routes/Home";
import Project from "./routes/Project";
import Contact from "./routes/Contact";

// Components
import Header from "./components/Header";
import ScrollIndicator from "./components/ScrollIndicator";
import ScrollToTop from "./components/ScrollToTop";
import { AppContextProvider } from "./context/AppContext";

function App() {
  return (
    <AppContextProvider>
      <Router>
        <Route
          render={() => (
            <Main>
              <ScrollIndicator />
              <ScrollToTop />
              <Reset />
              <Header />
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/project/:id" component={Project} />
                </Switch>
              </AnimatePresence>
            </Main>
          )}
        />
      </Router>
    </AppContextProvider>
  );
}

const Main = styled.main`
  position: relative;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.color.bud};
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 1px;
    background-color: ${({ theme }) => theme.color.vista};
    opacity: 0.1;
    content: "";
    z-index: 1;
  }
`;

export default App;
