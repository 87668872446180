import React from "react";
import styled from "styled-components";

// Utils
import media from "../utils/media";

const ArrowIcon = (props: any) => (
  <StyledArrowIcon width={82} height={82} viewBox='0 0 82 82' {...props}>
    <g
      fill='none'
      fillRule='evenodd'
      stroke='#FDFBFA'
      strokeWidth={2}
      strokeLinejoin='round'
      transform='translate(1 1)'>
      <Arrow
        d='M55 33l7 7-7 7m-38-7h45'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Circle cx={40} cy={40} r={40} />
    </g>
  </StyledArrowIcon>
);

const StyledArrowIcon = styled.svg`
  position: relative;
  transition: transform 0.4s ease;
  vertical-align: middle;
  will-change: transform;
  ${media.tablet`
    width: 70px
    height: 70px;
  `}
  ${media.small`
    width: 50px
    height: 50px;
  `}
`;
const Arrow = styled.path``;
const Circle = styled.circle`
  transition: stroke-dashoffset 0.3s ease;
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
`;

export default ArrowIcon;
