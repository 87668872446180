import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface ITitleTwoProps {
  white?: boolean;
}

const TitleTwo = styled.h2<ITitleTwoProps>`
  font-family: ${({ theme }) => theme.font.serifSemibold};
  font-weight: 600;
  color: ${({ theme }) => theme.color.ship};
  font-size: 3.6vw;
  line-height: 4.3vw;
  white-space: pre-line;
  margin-bottom: 1vw;
  ${media.small`
    font-size: 8vw;
    line-height: 10vw;
    white-space: normal;
  `}

  ${(props) =>
    props.white &&
    css`
      color: ${({ theme }) => theme.color.vista};
    `}
`;

export default TitleTwo;
