import styled from "styled-components";

// Utils
// import media from "../utils/media";

// Components
import InputAppearance from "./InputAppearance";

// Styles
const Input = styled.input`
  ${InputAppearance};
`;

export default Input;
