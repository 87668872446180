import React, { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Utils
import media from "../utils/media";
import { useAppContext } from "../context/AppContext";

// Components
import TitleFive from "./TitleFive";
import Facebook from "../components/icons/Facebook";
import Github from "../components/icons/Github";
import Twitter from "../components/icons/Twitter";

// Assets
import * as img from "../assets";

// Styles
const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 3vw ${({ theme }) => theme.layout.gutter};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  ${media.mobile`
    padding-top: 6vw;
    padding-bottom: 6vw;
  `}
`;
const LogoLink = styled(Link)`
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Logo = styled.img`
  height: 100%;
  ${media.mobile`
    width: 80%;
  `}
`;
const StyledTitleFive = styled(TitleFive as any)`
  display: flex;
  align-items: center;
  ${media.mobile`
    display: none;
  `}
`;
const SocialLinks = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 3vw;
  ${media.mobile`
    grid-column-gap: 0;
  `}
`;
const SocialLink = styled.a`
  min-width: 1vw;
  transition: opacity 0.3s ease;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  ${media.mobile`
    margin-left: 4vw;
    transform: scale(.8);
  `}
`;
const Line = styled.div`
  width: 3vw;
  height: 2px;
  background: ${({ theme }) => theme.color.vista};
  opacity: 0.4;
  margin: 0 3vw 0 2vw;
`;

// Return
const Header: React.FC = () => {
  const { headerClass } = useAppContext();
  const iconColor = useMemo(
    () => (headerClass === "project" ? "#3E364A" : "#5A655A"),
    [headerClass]
  );
  return (
    <Wrapper className={headerClass}>
      <LogoLink to='/'>
        <Logo src={img.logo} />
      </LogoLink>
      <SocialLinks>
        <StyledTitleFive white={"true" as any}>
          Follow <Line />
        </StyledTitleFive>
        <SocialLink
          rel='noopener noreferrer'
          target='_blank'
          href='https://github.com/gauchomatt'>
          <Github color={iconColor} />
        </SocialLink>
        <SocialLink
          rel='noopener noreferrer'
          target='_blank'
          href='https://www.facebook.com/lift22'>
          <Facebook color={iconColor} />
        </SocialLink>
        <SocialLink
          rel='noopener noreferrer'
          target='_blank'
          href='https://twitter.com/gauchomatt'>
          <Twitter color={iconColor} />
        </SocialLink>
      </SocialLinks>
    </Wrapper>
  );
};

export default Header;
