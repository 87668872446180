import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// Utils
import media from "../../utils/media";

// Data
import { projectIntros } from "../../data/projects";

// Components
import TitleTwo from "../../components/TitleTwo";
import TitleThree from "../../components/TitleThree";
import Paragraph from "../../components/Paragraph";
import Tags from "../../components/Tags";

// Assets
// import * as img from '../../assets';

// Styles
const Wrapper = styled.section`
  padding: 15vw ${({ theme }) => theme.layout.gutter};
  position: relative;
  border-top: 1px solid rgba(253, 251, 250, 0.1);
  ${media.mobile`
    padding-top: 20vw;
  `}
`;
const Intro = styled.div`
  position: relative;
`;
const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${media.mobile`
    display: block;
  `}
`;
const Project = styled(motion.article)`
  position: relative;
  display: block;
  margin: 17vw 7.5vw 0 7.5vw;
  align-self: flex-start;
  will-change: transform;
  z-index: 1;
  ${media.mobile`
    margin-top: 24vw;
  `}
  &:nth-child(odd) {
    align-self: flex-end;
    span {
      left: -5vw;
      right: auto;
      ${media.mobile`
        left: -8vw;
      `}
    }
  }
  &:nth-child(3) {
    img {
      ${media.tablet`
        width: 100% !important;
      `}
    }
  }
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    img {
      ${media.tablet`
        width: 100% !important;
      `}
    }
  }
  &.dark {
    h3,
    ul {
      color: ${({ theme }) => theme.color.vista};
    }
  }
  a {
    display: block;
    &:hover {
      .ribbon {
        width: 100%;
      }
    }
  }
`;
const Number = styled.span`
  font-size: 13vw;
  font-family: ${({ theme }) => theme.font.serif};
  color: ${({ theme }) => theme.color.mantle};
  position: absolute;
  right: -5vw;
  top: -4.5vw;
  text-align: center;
  ${media.mobile`
    font-size: 22vw;
    top: -12vw;
    right: -8vw;
  `}
`;
const Figure = styled.figure`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "top top" "bottom bottom";
  width: 27vw;
  height: 35.5vw;
  background: ${({ theme }) => theme.color.ship};
  position: relative;
  overflow: hidden;
  // ${media.desktop`
  //   transform: scale(1.1);
  // `}
  ${media.tablet`
    width: 40vw;
    height: 48vw;
  `}
  ${media.mobile`
    width: 100%;
    height: 100vw;
  `}
`;
const Image = styled.img`
  align-self: flex-end;
  grid-column: top-start / bottom-end;
  grid-row: top-start / bottom-end;
  margin-left: auto;
  mix-blend-mode: luminosity;
  }
  ${media.tablet`
    width: 54% !important;
    margin-top: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;
const Title = styled(TitleThree as any)`
  grid-area: bottom;
  align-self: flex-end;
  margin-bottom: 2vw;
  margin-left: 2vw;
  position: relative;
  z-index: 1;
  white-space: pre-line;
  ${media.tablet`
    font-size: 5vw;
    line-height: 6vw;
    margin-bottom: 4vw;
  `}
  ${media.mobile`
    font-size: 8vw;
    line-height: 8vw;
    margin-left: 5vw;
    margin-bottom: 10vw;
  `}
`;

const Ribbon = styled.div`
  transition: all 0.6s ${({ theme }) => theme.transition.cubicEase};
  position: absolute;
  width: 0;
  height: 10vw;
  bottom: 13vw;
  right: -2vw;
  left: -8vw;
  background: ${({ theme }) => theme.color.sweet};
  mix-blend-mode: soft-light;
  pointer-events: none;
  will-change: transform;
  ${media.mobile`
    height: 20vw;
    bottom: 20vw;
  `}
`;
const Description = styled(Paragraph as any)`
  font-size: 1vw;
  line-height: 1.5vw;
  color: ${({ theme }) => theme.color.dusk};
  margin-top: 1.5vw;
  margin-left: 2vw;
  ${media.mobile`
    margin-top: 2vw;
    margin-left: 5vw;
  `}
`;

// Const
const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const frameVariants = {
  hover: { scale: 0.95 },
};

// Return
function Section2() {
  return (
    <Wrapper>
      <Intro>
        <TitleTwo>Work</TitleTwo>
        <Paragraph preline={"true" as any}>
          {`We've had the opportunity to work with 
          some incredible companies along the way.
          Here's a sampling of just a few...`}
        </Paragraph>
      </Intro>
      <Grid>
        {projectIntros.map(
          (
            {
              version,
              num,
              tags,
              desc: { title, shortText },
              screenHome: { src, srcset, srcset3x },
              srcStyle,
            },
            i
          ) => (
            <Project
              key={i}
              className={version}
              whileHover='hover'
              variants={frameVariants}
              transition={transition}>
              <Link to={`/project/${i + 1}`}>
                <Number>{num}</Number>
                <Figure>
                  <Tags>
                    {tags.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </Tags>
                  <Title>{title}</Title>
                  <Image
                    src={src}
                    srcSet={`${srcset} 2x, ${srcset3x} 3x`}
                    alt={title}
                    style={srcStyle}
                  />
                </Figure>
                <Ribbon className='ribbon' />
                <Description>{shortText}</Description>
              </Link>
            </Project>
          )
        )}
      </Grid>
    </Wrapper>
  );
}

export default Section2;
