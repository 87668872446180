import React from "react";
import styled from "styled-components";
import Parallax from "react-plx";
import { motion } from "framer-motion";

// Utils
import media from "../utils/media";

// Components
import TitleOne from "./TitleOne";
import TitleTwo from "./TitleTwo";
import TitleThree from "./TitleThree";
import Paragraph from "./Paragraph";
import Tags from "./Tags";

// Assets
import * as img from "../assets";

// Styles
const ProjectWrap = styled.div``;
const Section = styled.article`
  display: grid;
  position: relative;
  padding: 10vw ${({ theme }) => theme.layout.gutter};
  background-color: ${({ theme }) => theme.color.dusk};
  border-bottom: 1px solid rgba(253, 251, 250, 0.1);
  ${media.small`
    padding-top: 16vw;
    padding-bottom: 16vw;
  `}
  &.back {
    padding: 0;
  }
  &.intro {
    grid-template-columns: 1fr 1fr 8vw 1fr 1fr;
    grid-template-areas: 'left left-center center right-center right';
    padding-top: 15vw;
    padding-bottom: 2vw;
    align-items: center;
    ${media.small`
      padding-top: 20vw;
    `}
  }
  &.desc {
    padding-left 8vw;
    ${media.mobile`
      z-index: 1;
      padding-left: 4vw;
    `}
    > * {
      max-width: 32vw;
      ${media.mobile`
        max-width: none;
      `}
    }
  }
  &.screen {
    z-index: 1;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.clay};
    padding-top: 13vw;
    padding-bottom: 24vw;
    border-bottom: none;
    .keelo {
      img {
        width: 125%;
        transform: translateX(-10%);
        ${media.mobile`
          width: 200%;
          transform: translateX(-25%);
        `}
      }
    }
  }
  &.quote {
    padding-top: 10vw;
    padding-bottom: 10vw;
    ${media.mobile`
      overflow-x: hidden;
    `}
  }
  &.product {
    grid-template-columns: .35fr 1fr;
    grid-template-areas: 'left right';
    grid-column-gap: 8vw;
    padding-top: 10vw;
    padding-bottom: 10vw;
    ${media.mobile`
      grid-template-columns: 1fr;
      grid-template-areas: unset;
      grid-row-gap: 10vw;
    `}
  }
`;
const Number = styled.div`
  position: relative;
  grid-area: left;
  font-size: 9.5vw;
  font-family: ${({ theme }) => theme.font.serif};
  color: ${({ theme }) => theme.color.vista};
  text-align: left;
  opacity: 0.1;
`;
const ProjectTags = styled(Tags)`
  grid-area: left-center;
  color: ${({ theme }) => theme.color.vista};
  margin-left: auto;
`;
const Visit = styled(motion.a)`
  background: ${({ theme }) => theme.color.clay};
  box-shadow: 0 1.5vw 2vw -1.5vw rgba(36, 33, 42, 0.3);
  border-radius: 0.7vw;
  margin: 3vw 0 0 0;
  padding: 1.7vw 2vw;
  font-size: 1vw;
  line-height: 1.6vw;
  color: ${({ theme }) => theme.color.ship};
  font-family: ${({ theme }) => theme.font.sansSerifBold};
  letter-spacing: 0.05vw;
  max-width: 18vw !important;
  display: flex;
  align-items: center;
  ${media.desktop`
    font-size: 16px;
    max-width: 24vw !important;
  `}
  ${media.tablet`
    padding: 2.5vw 3vw;
    max-width: 34vw !important;
  `}
  ${media.mobile`
    max-width: 60% !important;
    margin-top: 10vw;
  `}
  ${media.small`
    font-size: 14px;
    line-height: 24px;
    padding: 24px 30px;
    border-radius: 14px;
  `}
`;
const LinkIcon = styled.img`
  margin-left: auto;
`;
const Screen = styled.figure`
  position: relative;
  width: 84vw;
  height: 43.8vw;
  border-radius: 2.1vw;
  margin: 0 auto;
  background: ${({ theme }) => theme.color.space};
  box-shadow: 0 0.21vw 0.165vw -1.5vw rgba(0, 0, 0, 0.02),
    0 0.5vw 0.395vw -1.5vw rgba(0, 0, 0, 0.028),
    0 0.94vw 0.745vw -1.5vw rgba(0, 0, 0, 0.035),
    0 1.675vw 1.33vw -1.5vw rgba(0, 0, 0, 0.042),
    0 3.135vw 2.485vw -1.5vw rgba(0, 0, 0, 0.05),
    0 7.5vw 5.95vw -1.5vw rgba(0, 0, 0, 0.07);
`;
const ScreenInner = styled.div`
  position: absolute;
  top: 2.8vw;
  right: 2.8vw;
  bottom: 2.8vw;
  left: 2.8vw;
  border-radius: 1.05vw;
  overflow: hidden;
  background: ${({ theme }) => theme.color.vista};
  z-index: 10;
  img {
    width: 100%;
    overflow: hidden;
  }
  .image {
    will-change: transform;
  }
`;
const ShapesWrapper = styled.div`
  position: relative;
  z-index: 1;
`;
const ShapesQuote = styled.img`
  width: 100%;
  position: relative;
  left: -14vw;
  ${media.mobile`
    width: 180%;
  `}
`;
const QuoteBlock = styled.blockquote`
  position: absolute;
  top: 26vw;
  left: 10vw;
  max-width: 35vw;
  z-index: 1;
  ${media.mobile`
    top: 50vw;
    left: 25vw;
    max-width: 60vw;
  `}
`;
const QuotePic = styled.img`
  border-radius: 100%;
  width: 5vw;
  margin-bottom: 1.75vw;
`;
const Quote = styled(TitleThree as any)`
  color: ${({ theme }) => theme.color.vista};
  font-family: ${({ theme }) => theme.font.serifSemiboldItalic};
  margin-bottom: 3vw;
`;
const QuoteName = styled.cite`
  color: ${({ theme }) => theme.color.dusk};
  font-size: 1.2vw;
  font-weight: normal;
  line-height: 1.75vw;
  letter-spacing: 0.05vw;
  ${media.mobile`
    font-size: 18px;
    line-height: 20px;
    letter-spacing: normal;
  `}
  ${media.small`
    font-size: 13px;
    line-height: 18px;
  `}
`;
const Col = styled.div`
  position: relative;
  z-index: 1;
`;
const Sticky = styled.div`
  position: sticky;
  top: 5vw;
  ${media.mobile`
    position: relative;
    top: auto;
    max-width: 40vw;
  `}
`;
const Description = styled(Paragraph as any)`
  font-size: 1vw;
  line-height: 1.5vw;
  color: ${({ theme }) => theme.color.ship};
  margin-top: 1.5vw;
  margin-left: 2vw;
`;
const Product = styled.figure`
  margin: 0 0 8.5vw 0;
  > img {
    box-shadow: 0 5vw 8vw -4vw rgba(36, 33, 42, 0.4);
    width: 100%;
  }
  &.keelo {
    &:nth-child(1) {
      overflow: hidden;
      margin-right: -${({ theme }) => theme.layout.gutter};
      img {
        box-shadow: none;
        margin-left: 25vw;
        ${media.mobile`
          margin-left: 35vw;
        `}
      }
    }
    &:nth-child(2) {
      img {
        box-shadow: none;
      }
    }
  }
`;

// Const
const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };
const frameVariants = {
  hover: { scale: 0.95 },
};
const parallaxImage = [
  {
    start: ".image",
    startOffset: 100,
    end: ".product",
    properties: [
      {
        startValue: 0,
        endValue: -300,
        property: "translateY",
      },
    ],
  },
];

// Return
const ProjectDetail = ({
  data: { projectKey, num, tags, desc, screen, quote, product },
}: any) => {
  return (
    <ProjectWrap>
      <Section className='intro'>
        <Number>{num}</Number>
        <ProjectTags>
          {tags.map((value: any, index: number) => (
            <li key={index}>{value}</li>
          ))}
        </ProjectTags>
      </Section>
      <Section className='desc'>
        <TitleOne ship={"true" as any}>{desc.title}</TitleOne>
        <Paragraph>{desc.text}</Paragraph>
        {desc.link && (
          <Visit
            whileHover='hover'
            variants={frameVariants}
            transition={transition}
            href={desc.link}
            target='_blank'>
            Visit {desc.title}
            <LinkIcon src={img.link} />
          </Visit>
        )}
      </Section>
      <Section className='screen'>
        <Screen className={projectKey}>
          <ScreenInner>
            <Parallax parallaxData={parallaxImage} className='image'>
              <img
                alt={screen.alt}
                src={screen.src}
                srcSet={`${screen.srcset} 2x, ${screen.srcset3x} 3x`}
              />
            </Parallax>
          </ScreenInner>
        </Screen>
      </Section>
      <Section className='quote'>
        <ShapesWrapper>
          <QuoteBlock>
            <QuotePic
              alt={quote.alt}
              src={quote.src}
              srcSet={`${quote.srcset} 2x, ${quote.srcset3x} 3x`}
            />
            <Quote>{quote.text}</Quote>
            <QuoteName>— {quote.author}</QuoteName>
          </QuoteBlock>
          <ShapesQuote src={img.shapesQuote} />
        </ShapesWrapper>
      </Section>
      <Section className='product'>
        <Col>
          <Sticky>
            <TitleTwo>{product.title}</TitleTwo>
            <Paragraph>{product.desc}</Paragraph>
          </Sticky>
        </Col>
        <Col>
          {product.products.map((value: any, index: number) => (
            <Product key={index} className={projectKey}>
              <img
                alt={value.alt}
                src={value.src}
                srcSet={`${value.srcset} 2x, ${value.srcset3x} 3x`}
              />
              <Description>{value.desc}</Description>
            </Product>
          ))}
        </Col>
      </Section>
    </ProjectWrap>
  );
};

export default ProjectDetail;
