import styled, { css } from "styled-components";

// Utils
import media from "../utils/media";

export interface IParagraphProps {
  preline?: boolean;
}

const Paragraph = styled.p<IParagraphProps>`
  color: ${({ theme }) => theme.color.ship};
  font-size: 1.2vw;
  font-weight: normal;
  line-height: 2vw;
  letter-spacing: 0.05vw;
  white-space: pre-line;
  margin-bottom: 2vw;

  ${media.desktop`
    font-size: 1.3vw;
    line-height: 2.3vw;
  `}
  ${media.tablet`
    font-size: 1.5vw;
    line-height: 2.75vw;
  `}
  ${media.mobile`
    font-size: 16px;
    line-height: 26px;
  `}
  ${media.small`
    white-space: normal;
    font-size: 14px;
    line-height: 24px;
  `}
  &:last-of-type {
    margin-bottom: 0;
  }

  // Props
  ${(props) =>
    props.preline &&
    css`
      white-space: pre-line !important;
    `}
`;

export default Paragraph;
