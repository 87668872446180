import styled from "styled-components";
import { rgba } from "polished";

// Utils
import media from "../utils/media";

// Styles
export const FormLabel = styled.label`
  display: block;
  font-size: 1vw;
  margin-bottom: 0.75vw;
  color: ${({ theme }) => theme.color.ship};
  ${media.tablet`
    font-size: 16px;
    margin-bottom: 8px;
  `}
`;

export const FormError = styled.div`
  margin-top: 10px;
  display: block;
  font-size: 14px;
  padding: 10px;
  border-radius: 0.7vw;
  color: ${({ theme }) => theme.color.sweet};
  background: ${({ theme }) => rgba(theme.color.sweet, 0.1)};
`;

const FormControl = styled.fieldset`
  position: relative;
  margin-bottom: 1.5vw;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${media.tablet`
    margin-bottom: 20px;
  `}
`;

export default FormControl;
