import styled from "styled-components";

// Utils
import media from "../utils/media";

// Components
import InputAppearance from "./InputAppearance";

// Styles
const Textarea = styled.textarea`
  ${InputAppearance};
  resize: none;
  height: 12vw;
  ${media.mobile`
    height: 200px;
  `}
`;

export default Textarea;
