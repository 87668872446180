import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Utils
import media from "../../utils/media";

// Components
import TitleOne from "../../components/TitleOne";
import Button from "../../components/Button";
import ArrowIcon from "../../components/ArrowIcon";

// Assets
import * as img from "../../assets";
import { ReactComponent as Shapes } from "../../assets/svg/shapes.svg";

// Styles
const Wrapper = styled.section`
  padding: 15vw ${({ theme }) => theme.layout.gutter};
  padding-bottom: 61vw;
  min-height: 100vh;
  grid-column: span 2;
  position: relative;
  ${media.mobile`
    padding-top: 20vw;
    min-height: unset;
  `}
  ${media.small`
    padding-top: 30vw;
    padding-bottom: 26vw;
  `}
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8vw;
  position: relative;
  z-index: 1;
  ${media.tablet`
    margin-right: 0;
  `}
`;
const ShapesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  > svg {
    position: relative;
    left: -5vw;
    top: -27vw;
    ${media.tablet`
      width: 170%;
      height: 100%;
    `}
    ${media.mobile`
      top: -8vw;
      opacity: 0.3;
    `}
  }
`;
const Mark = styled.img`
  margin: 0 0 3vw auto;
  display: flex;
  width: 7vw;
  ${media.tablet`
    width: 10vw;
    margin-bottom: 5vw;
  `}
  ${media.mobile`
    margin-left: 0;
    width: 13vw;
  `}
  ${media.small`
    width: 16vw;
  `}
`;
const Title = styled(TitleOne as any)`
  text-align: right;
  ${media.mobile`
    text-align: left;
  `}
`;
const SubTitle = styled.p`
  text-align: right;
  margin-top: 3vw;
  font-size: 2vw;
  line-height: 2.7vw;
  letter-spacing: 0.05vw;
  white-space: pre-line;
  color: ${({ theme }) => theme.color.lunar};
  ${media.tablet`
    margin-top: 4vw;
    font-size: 3vw;
    line-height: 4vw;
  `}
  ${media.mobile`
    text-align: left;
    font-size: 4vw;
    line-height: 6vw;
    white-space: normal;
  `}
`;
const ButtonLink = styled(Link)`
  margin: 3vw 0 0 auto;
  display: inline-flex;
  ${media.tablet`
    margin-bottom: 0;
  `}
  ${media.mobile`
    margin-left: 0;
  `}
  > button {
    padding: 0;
  }
`;

// Return
function Section1() {
  return (
    <Wrapper>
      <ShapesWrapper>
        <Shapes />
      </ShapesWrapper>
      <Content>
        <Mark
          src={img.mark1}
          srcSet={img.mark2 + " 2x," + img.mark3 + " 3x"}></Mark>
        <Title>Pushing boundaries.</Title>
        <SubTitle>
          {`A design + development studio focused
        on building incredible products.`}
        </SubTitle>
        <ButtonLink to='/contact'>
          <Button transparent>
            Let's Work Together
            <ArrowIcon />
          </Button>
        </ButtonLink>
      </Content>
    </Wrapper>
  );
}

export default Section1;
