import React, { createContext, Context, useContext, useState } from "react";

const AppContext: Context<any> = createContext({});

export interface ITitleFiveProps {
  white?: boolean;
}

export const AppContextProvider = ({ children, ...props }: any) => {
  const [headerClass, setHeaderClass] = useState("");

  return (
    <AppContext.Provider
      {...props}
      value={{
        headerClass,
        setHeaderClass,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw Error(
      "The `useAppContext` hook must be called from a descendent of the `AppContext`."
    );
  }

  return {
    setHeaderClass: ctx.setHeaderClass,
    headerClass: ctx.headerClass,
  };
};
